'use client';

import Cal, { getCalApi } from '@calcom/embed-react';
import { useStore } from '@nanostores/react';
import { themeStore } from '@/stores/themeStore';
import { useEffect } from 'react';

function CalCom() {
  const theme = useStore(themeStore);

  useEffect(() => {
    (async function configureCalendar() {
      const cal = await getCalApi();
      cal('ui', {
        styles: { branding: { brandColor: '#111827' } },
        hideEventTypeDetails: false,
        layout: 'month_view',
        theme: theme === 'dark' ? 'dark' : 'light',
      });
    })();
  }, [theme]);

  return (
    <Cal
      className="my-8"
      calLink="ganesh-mani-009/30-mins-meeting"
      style={{ width: '100%', height: '100%', overflow: 'scroll' }}
      config={{ layout: 'month_view' }}
    />
  );
}

export default CalCom;
